import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";
import "./App.css";
import Address from "./components/Address";

const RootView = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
  padding: 0.25em 1em;
`;

const Title = styled.h2`
  text-align: center;
  padding-bottom: 1em;
`;

const SubmitBtn = styled.a`
   display: inline-block;
   padding: 0.4em 1.2em;
   margin: 2em 0.1em 0.1em 0;
   border-radius: 0.2em;
   box-sizing: border-box;
   text-decoration: none;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   color: #ffffff;
   background-color: #ee4e34;
   box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
   text-align: center;
   position: relative;
  align-self: flex-end;
`;

const MissingAddressText = styled.div`
  color: red;
  font-size: 14px;
`;

const ConfirmationText = styled.span`
  font-size: 1.4em;
  color: #666;
  text-align: center;
`;

function App() {
  // Query params passed to webapp
  const urlParams = new URLSearchParams(window.location.search);

  const lang = urlParams.get("lang") || "fr";
  const idOrder = urlParams.get("idOrder");
  const name = urlParams.get("name");
  const nbAddress = parseInt(urlParams.get("nbAddress")) || 0;
  const tokenSession = urlParams.get("tokenSession");
  const authorizedIdCustomer = urlParams.get("authorizedIdCustomer");
  const authorizedLogin = urlParams.get("authorizedLogin");

  const [addressEditors, setAddressEditors] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [stringAddresses, setStringAddresses] = useState([]);
  const [missingAddress, setMissingAddress] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const addressArr = [];
    for (let i = 0; i < nbAddress; i++) {
      addressArr.push(
        <Address
          key={i}
          index={i + 1}
          updateAddress={(addressString, address) =>
            updateAddress(addressString, address, i)
          }
        />
      );
    }
    setAddressEditors(addressArr);
  }, [nbAddress]);

  const updateAddress = (addressString, address, index) => {
    stringAddresses[index] = addressString;
    setStringAddresses(stringAddresses);
    addresses[index] = address;
    setAddresses(addresses);
    setMissingAddress(false);
  };

  const submitReservation = async () => {
    // Check if all addresses set
    setMissingAddress(false);
    if (nbAddress !== addresses.length) {
      setMissingAddress(true);
    } else {
      for (const address of addresses) {
        if (!address) {
          setMissingAddress(true);
          break;
        }
      }
    }

    setSubmitting(true);

    // Call service to confirm customer addresses for reservation
    const response = await fetch(
      `https://kioskgourmet-dev.mediarl.workers.dev/v1/reservation/address`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          idOrder,
          name,
          addresses,
          tokenSession,
          authorizedIdCustomer,
          authorizedLogin
        })
      }
    );

    setSuccess(true);
    setSubmitting(false);
  };

  return (
    <RootView>
      <Title>Reservation</Title>

      {!success && (
        <>
          {!submitting && (
            <>
              <div>{addressEditors}</div>
              {missingAddress && (
                <MissingAddressText>Missing address</MissingAddressText>
              )}
              <SubmitBtn onClick={submitReservation}>
                Confirm reservation
              </SubmitBtn>
            </>
          )}

          {submitting && (
            <PuffLoader
              color="#ee4e34"
              loading={submitting}
              size={60}
              css={{ marginTop: 20, alignSelf: "center" }}
            />
          )}
        </>
      )}

      {success && (
        <ConfirmationText>
          Thanks! Your reservation is confirmed.
        </ConfirmationText>
      )}
    </RootView>
  );
}

export default App;
