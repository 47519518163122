import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Button = styled.div`
  color: #444;
  font-size: 1em;
  margin: 1em 0;
  padding: 0.25em 1em;
  border: 1px solid #ddd;
  border-radius: 3px;
`;

const EditionView = styled.div`
  color: #444;
  font-size: 1em;
  margin: 1em 0;
  padding: 0.25em 1em;
`;

const FormView = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormInput = styled.input`
  width: ${props => (props.width > 600 ? "30em" : "100%")};
  height: 1.8em;
  margin-bottom: 0.5em;
  border: 1px solid #ddd;
`;

const FormBtnView = styled.div`
  flex-direction: row;
  margin: 0.5em 0;
`;

const FormBtn = styled.a`
   display: inline-block;
   padding: 0.4em 1.2em;
   margin: 0 0.1em 0.1em 0;
   border-radius: 0.2em;
   box-sizing: border-box;
   text-decoration: none;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   color: ${props => (props.color ? props.color : "#ffffff")};
   background-color: ${props => props.bgColor};
   box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
   text-align: center;
   position: relative;
`;

const EditIcon = styled.img`
  width: 1em;
  height: 1em;
  padding-left: 0.5em;
`;

function Address(props) {
  const { width, height } = useWindowDimensions();

  const [editMode, setEditMode] = useState(false);
  const [guestName, setGuestName] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [addrState, setAddrState] = useState();
  const [addressString, setAddressString] = useState();

  const toggleEditMode = () => {
    setEditMode(!editMode);
    console.log("Toggle to edit mode: " + editMode, width, height);
  };

  const updateAddress = () => {
    setEditMode(false);
    props.updateAddress(addressString, {
      otherGuestName: guestName,
      address: addressLine1,
      city,
      postalCode,
      state: addrState
    });
  };

  useEffect(() => {
    if (guestName && addressLine1 && city && postalCode && addrState) {
      setAddressString(
        `${guestName}, ${addressLine1}, ${city}, ${postalCode}, ${addrState}`
      );
    } else {
      setAddressString(null);
    }
  }, [guestName, addressLine1, city, postalCode, addrState]);

  return (
    <div>
      {/* Read mode (without address) */}
      {!editMode && !addressString && (
        <Button onClick={toggleEditMode}>
          Address {props.index} <EditIcon src="edit.png" />
        </Button>
      )}

      {/* Read mode (with address) */}
      {!editMode && addressString && (
        <Button onClick={toggleEditMode}>
          {addressString} <EditIcon src="edit.png" />
        </Button>
      )}

      {/* Edit mode */}
      {editMode && (
        <div>
          <span>Address {props.index}</span>
          <EditionView>
            <FormView>
              <FormInput
                type="text"
                value={guestName}
                width={width}
                placeholder="Guest name"
                onChange={event => setGuestName(event.target.value)}
              />
              <FormInput
                type="text"
                value={addressLine1}
                width={width}
                placeholder="Address line"
                onChange={event => setAddressLine1(event.target.value)}
              />
              <FormInput
                type="text"
                value={city}
                width={width}
                placeholder="City"
                onChange={event => setCity(event.target.value)}
              />
              <FormInput
                type="text"
                value={postalCode}
                width={width}
                placeholder="Postal code"
                onChange={event => setPostalCode(event.target.value)}
              />
              <FormInput
                type="text"
                value={addrState}
                width={width}
                placeholder="State"
                onChange={event => setAddrState(event.target.value)}
              />
              <FormBtnView>
                <FormBtn
                  onClick={() => setEditMode(false)}
                  bgColor="#CCCCCC"
                  color="#000000"
                >
                  Cancel
                </FormBtn>
                <FormBtn onClick={updateAddress} bgColor="#ee4e34">
                  Update
                </FormBtn>
              </FormBtnView>
            </FormView>
          </EditionView>
        </div>
      )}
    </div>
  );
}

export default Address;
